export default {
  gateway: {
    baseUrl: 'https://dbendangmelaka.com:8090',
    token: 'REJlbmRhbmdNZWxha2FEaWJhd2FrYW5PbGVoSGlwaHViTVk',
    graphqlUrl: ''
  },
  app: {
    baseUrl: ''
  }
}
